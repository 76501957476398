import { useCallback, useEffect, useState } from 'react';

import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';

import type { GetStaticProps, NextPage } from 'next';

import { useMediaQuery } from '@mui/material';

import lazyHydrate from 'next-lazy-hydrate';
import { useQuery } from 'react-query';
import { Element, scroller } from 'react-scroll';
import { useRecoilValue } from 'recoil';

import { useWindowSize } from '@hooks_common/useWindowSize';

import { getMarketData, getSalesStats } from '@hooks_iqfu/public/useMarketData';
import { getSaleCasesList } from '@hooks_iqfu/public/useSaleCase';

import { validatePrefectureCode } from '@utils_iqfu/hasValidPrefectureCode';

import CheckPrice from '@components_iqfu/public/check_price/CheckPrice';
import FloatingCV from '@components_iqfu/public/floating_cv/floating_cv';
import PricePageLinks from '@components_iqfu/public/price_page_links/PricePageLinks';
import PublicPageContainer from '@components_iqfu/public/public_page_container/PublicPageContainer';
import SearchCompaniesStepper from '@components_iqfu/public/search_companies_stepper/SearchCompaniesStepper';
import SearchCompaniesWrapper from '@components_iqfu/public/search_companies_wrapper/SearchCompaniesWrapper';
import SearchLocalCompanies from '@components_iqfu/public/search_local_companies/SearchLocalCompanies';
import SectionLeadLeftAlign from '@components_iqfu/public/section_lead_left_align/SectionLeadLeftAlign';

import publicHeaderHeight from '@recoil_iqfu/atoms/public/publicHeaderHeight';

import { MarketData, SalesStatsData } from '@type-def_iqfu/public/MarketData';
import { SaleCaseItem } from '@type-def_iqfu/public/SaleCaseItem';

import { MediaQuery } from '@constants_common';
import { CompaniesPageCategory, ISRRevalidateTime } from '@constants_iqfu';
import styles from '@styles_iqfu/public/top.module.scss';

const ArticlesForBeginner = lazyHydrate(
  () => import('@components_iqfu/public/articles_for_beginner/ArticlesForBeginner'),
  {
    on: [
      ['scroll', () => document],
      ['delay', 500],
    ],
    compatibleMode: true,
  },
);

const CTA = lazyHydrate(() => import('@components_iqfu/public/cta/CTA'), {
  on: [
    ['scroll', () => document],
    ['delay', 500],
  ],
  compatibleMode: true,
});

const UsersVoice = lazyHydrate(() => import('@components_iqfu/public/users_voice/UsersVoice'), {
  on: [
    ['scroll', () => document],
    ['delay', 500],
  ],
  compatibleMode: true,
});

const MarketInfo = lazyHydrate(() => import('@components_iqfu/public/market_info/MarketInfo'), {
  on: [
    ['scroll', () => document],
    ['delay', 500],
  ],
  compatibleMode: true,
});

const SaleCaseArea = lazyHydrate(() => import('@components_iqfu/public/sale_case_area/SaleCaseArea'), {
  on: [
    ['scroll', () => document],
    ['delay', 500],
  ],
  compatibleMode: true,
});

const PrefectureSelectMap = lazyHydrate(
  () => import('@components_iqfu/public/prefecture_select_map/PrefectureSelectMap'),
  {
    on: [
      ['scroll', () => document],
      ['delay', 500],
    ],
    compatibleMode: true,
  },
);

const Footer = lazyHydrate(() => import('@components_iqfu/public/footer/Footer'), {
  on: [
    ['scroll', () => document],
    ['delay', 500],
  ],
  compatibleMode: true,
});

const pageLabel = 'home';

export const getStaticProps: GetStaticProps = async () => {
  const fetchSalesStats = async (propertyType: 'land' | 'house' | 'mansion') => {
    const salesStats = await getSalesStats({ property_type: propertyType });
    return salesStats.regions_average_prices ? salesStats : null;
  };

  const fetchMarketData = async () => {
    const fetchedMarketData = await getMarketData({});
    return fetchedMarketData.area_name ? fetchedMarketData : null;
  };

  const [saleCaseList, landSalesStats, houseSalesStats, mansionSalesStats, marketData]: [
    {
      sale_cases: SaleCaseItem[];
    },
    SalesStatsData | null,
    SalesStatsData | null,
    SalesStatsData | null,
    MarketData | null,
  ] = await Promise.all([
    getSaleCasesList({ limit: 20 }),
    fetchSalesStats('land'),
    fetchSalesStats('house'),
    fetchSalesStats('mansion'),
    fetchMarketData(),
  ]);

  return {
    props: {
      landSalesStats,
      houseSalesStats,
      mansionSalesStats,
      saleCasesList: saleCaseList?.sale_cases || [],
      marketData,
    },
    revalidate: ISRRevalidateTime,
  };
};

//json dataになるので、undefinedは不可
type Props = {
  landSalesStats: SalesStatsData | null;
  houseSalesStats: SalesStatsData | null;
  mansionSalesStats: SalesStatsData | null;
  saleCasesList: SaleCaseItem[];
  marketData: MarketData | null;
};

const TopPage: NextPage<Props> = ({
  saleCasesList,
  marketData,
  landSalesStats,
  houseSalesStats,
  mansionSalesStats,
}) => {
  const router = useRouter();
  const [dispalySaleCasesData, setDispalySaleCasesData] = useState(saleCasesList);
  const { data: updateSaleCasesData } = useQuery(['topPageSaleCases'], () => getSaleCasesList({ limit: 20 }));
  useEffect(() => {
    if (updateSaleCasesData) {
      setDispalySaleCasesData(updateSaleCasesData?.sale_cases);
    }
  }, [updateSaleCasesData]);

  const headerHeight = useRecoilValue(publicHeaderHeight);

  const [showFloatingThreshold, setShowFloatingThreshold] = useState(0);
  const isMobile = useMediaQuery(MediaQuery.lg);
  const { height: windowHeight } = useWindowSize();
  const showFloatingRef = useCallback(
    async (node: HTMLHeadingElement) => {
      if (isMobile) {
        await new Promise((resolve) => setTimeout(resolve, 200));
        setShowFloatingThreshold(node?.getBoundingClientRect().top + document.documentElement.scrollTop - windowHeight);
      }
    },
    [windowHeight],
  );

  const metaTitle =
    '簡単で素早い査定と高値での不動産売却・買取を実現。地元で不動産売却に強い会社をランキング形式で比較|不動産売却・買取専門のイクラ不動産トップ';

  const metaDescription =
    '「どこよりも簡単で素早い査定」と「自分に合った、高値での不動産売却・買取」を実現する不動産売却専門サイト、「イクラ不動産」のTOPページです。不動産の売却相場・価格の調査や査定が素早く・簡単にできるのはもちろん、弊社で審査して厳選した、全国各地で地元の「土地・（中古）マンション・（中古）一戸建て」の売却・買取・査定に強い、おすすめの加盟不動産会社をランキング形式で比較検討できます。また、「一戸建ての売却が得意な不動産屋さん」「マンションの売却に強い不動産屋さん」といった「強み別」の不動産会社ランキングもあるので、自分に合った切り口で不動産会社を比較・検討できます。併せて、全国の不動産売却市場・相場の動向や、売却に必要な知識から実際にあった売却事例まで、不動産売却をするにあたって必要な情報も掲載しています。';

  const metaOGUrl = `${process.env.NEXT_PUBLIC_CLIENT_BASEPATH}`;

  const [landingPrefecture, setLandingPrefecture] = useState<string | undefined>(undefined);
  const [landingCity, setLandingCity] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (router.query.prefecture_code && !router.query.city_code) {
      const prefectureParam = String(router.query.prefecture_code);

      const hasValidPrefectureCode = validatePrefectureCode(prefectureParam);

      if (hasValidPrefectureCode) {
        setLandingPrefecture(String(router.query.prefecture_code));
      }
    }
    if (router.query.city_code) {
      //prefecture_codeはcity_codeから末尾3桁を削除したもの
      const prefecture_code = String(router.query.city_code).slice(0, -3);

      const hasValidPrefectureCode = validatePrefectureCode(prefecture_code);
      if (hasValidPrefectureCode) {
        setLandingPrefecture(prefecture_code);
        setLandingCity(String(router.query.city_code));
      }
    }

    if (router.query.position) {
      scroller.scrollTo(String(router.query.position), {
        duration: 250,
        smooth: true,
        offset: -headerHeight - 4,
      });
    }
  }, [router.query]);

  return (
    <PublicPageContainer>
      <Head>
        <title key="title">{metaTitle}</title>
        <meta name="description" content={metaDescription} key="description" />
        <meta property="og:title" content={metaTitle} key={'og_title'} />
        <meta property="og:description" content={metaDescription} key={'og_description'} />
        <meta property="og:url" content={metaOGUrl} key={'og_url'} />
        <link rel="canonical" href={metaOGUrl} />
        <meta name="twitter:title" content={metaTitle} key={'twitter_title'} />
        <meta name="twitter:description" content={metaDescription} key={'twitter_description'} />
        {/* LCPを先読みする */}
        <link rel="preload" as="image" href={'/images/iqfu/public/search_companies_stepper/town_illust_bg.webp'} />
        <link rel="canonical" href={metaOGUrl} />
      </Head>
      <div className={styles.page_container}>
        <div className={styles.key_visual}>
          <div className={styles.key_visual_inner}>
            <div className={styles.key_visual_lead_container}>
              <h1 className={styles.key_visual_h1}>不動産売却・買取専門サイト　イクラ不動産</h1>
              <p className={styles.key_visual_lead}>
                <span className={styles.orange}>自分に合った</span>
                <br className="sp_only" />
                不動産売却・買取
              </p>
              <h2 className={styles.key_visual_h2}>
                簡単で素早い査定と高値での不動産売却・買取を実現。
                <br className="pc_only" />
                地元で売却に強い不動産会社をランキング形式で比較できる！
              </h2>
            </div>
            <div className={styles.key_visual_ikuko_image}>
              <picture>
                <source srcSet="/images/iqfu/public/top/kv_ikuko.webp" media="(min-width:993px)" />
                <source srcSet={'/images/iqfu/public/top/kv_ikuko_mobile.webp'} media="(max-width:992px)" />
                <img src={'/images/iqfu/public/top/kv_ikuko.webp'} alt="" />
              </picture>
            </div>
            <div className={styles.cm_badge}>
              <img src={'/images/iqfu/tv_badge.png'} alt="テレビCM 放映中"></img>
            </div>
          </div>
        </div>

        <div className={styles.search_companies_stepper_container}>
          <SearchCompaniesWrapper pageLabel={pageLabel}>
            <PricePageLinks pageLabel={pageLabel} areaInfo={{ areaName: '全国', areaType: 'country' }} />
            <SearchLocalCompanies pageLabel={pageLabel} location={'upper'} />
            <SearchCompaniesStepper
              pageLabel={pageLabel}
              defaultPrefecture={landingPrefecture}
              defaultCity={landingCity}
              hasRecommendButton
              location={'upper'}
            />
          </SearchCompaniesWrapper>
        </div>

        <div className={styles.cm_area_container}>
          <div className={styles.cm_lead_container}>
            <SectionLeadLeftAlign>イクラで納得の売却ができる理由</SectionLeadLeftAlign>
          </div>
          <div className={styles.cm_area_desktop_stacks}>
            <div className={styles.cm_movie_wrapper}>
              <div className={styles.cm_movie_badge}>
                CM <br />
                放映中
              </div>
              <iframe
                className={styles.cm_movie}
                width="560"
                height="315"
                src="https://www.youtube.com/embed/oAZK-bunRd4?si=T4JryWo5JBdyzYlP"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
            <div className={styles.cm_summary_wrapper}>
              <div>
                <h3 className={styles.cm_summary_lead}>全国20万件以上の売却実績データ</h3>
                <p className={styles.cm_summary_detail}>
                  全国20万件以上の売却実績データをもとに、あなたの物件があるエリアで
                  <span className={styles.cm_summary_detail_bold}>売却に強い信頼できる不動産会社をご紹介</span>。
                </p>
              </div>
              <div>
                <h3 className={styles.cm_summary_lead}>専門スタッフが丁寧にサポート</h3>
                <p className={styles.cm_summary_detail}>
                  納得の価格でお家が売れるまで、専門スタッフが丁寧にサポート。
                  <span className={styles.cm_summary_detail_bold}>売却の疑問をなんでも気軽に相談できます</span>。
                </p>
              </div>
            </div>
          </div>
          {/* end of .cm_area_desktop_stacks */}
          <div className={styles.cm_cv_area}>
            <p className={styles.cm_cv_mirco_copy}>初めての売却もイクラなら安心！</p>
            <Link href="/signup_inquiry" className={styles.cm_area_cv_button}>
              売却相談をはじめる（無料）
            </Link>
          </div>
        </div>

        <div className={styles.check_price_container}>
          <CheckPrice
            pageLabel={pageLabel}
            searchOption={{
              area: 'country',
            }}
          />
        </div>

        <div className={`${styles.cta_container}`} ref={showFloatingRef}>
          <CTA
            internalLink={{
              path: '/companies',
              text: `【全国版】${CompaniesPageCategory.default}不動産屋さんランキング`,
            }}
          />
        </div>

        <div className={styles.articles_for_beginner_container}>
          <ArticlesForBeginner pageLabel={pageLabel} />
        </div>

        <Element name="voice">
          <div className={styles.users_voice_container}>
            <UsersVoice />
          </div>
        </Element>

        <div className={styles.marketData_container}>
          <MarketInfo marketDataTitle={'全国の不動産売却の「動向」'} marketData={marketData} />
        </div>
        <div className={styles.sale_case_area_container}>
          <div className={styles.saleCase_lead_container}>
            <SectionLeadLeftAlign>【全国】直近の売却実績一覧</SectionLeadLeftAlign>
          </div>

          <SaleCaseArea saleCasesData={dispalySaleCasesData} pageLabel={pageLabel} />
        </div>

        <Element name="area">
          <div className={styles.japan_map_section_title}>
            <SectionLeadLeftAlign>「都道府県別」地元の不動産屋さんを探す・相場を調べる</SectionLeadLeftAlign>
          </div>
          <div className={styles.prefectures_map_container}>
            <PrefectureSelectMap
              landSalesStats={landSalesStats}
              houseSalesStats={houseSalesStats}
              mansionSalesStats={mansionSalesStats}
            />
          </div>
        </Element>

        <div className={styles.search_companies_stepper_container_bottom}>
          <SearchCompaniesWrapper hasShadow>
            <PricePageLinks pageLabel={pageLabel} areaInfo={{ areaName: '全国', areaType: 'country' }} />
            <SearchLocalCompanies pageLabel={pageLabel} location={'lower'} />
            <SearchCompaniesStepper pageLabel={pageLabel} hasRecommendButton location={'lower'} />
          </SearchCompaniesWrapper>
        </div>

        <div className={styles.articles_for_beginner_container}>
          <ArticlesForBeginner pageLabel={pageLabel} />
        </div>

        <div className={`${styles.cta_container_bottom}`}>
          <CTA
            internalLink={{
              path: '/companies',
              text: `【全国版】${CompaniesPageCategory.default}不動産屋さんランキング`,
            }}
          />
        </div>
      </div>
      <FloatingCV pageLabel={pageLabel} showFloatingThreshold={showFloatingThreshold} />
      <Footer />
    </PublicPageContainer>
  );
};

export default TopPage;
